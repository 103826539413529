import { graphql, useStaticQuery } from 'gatsby';
import gql from 'graphql-tag';
import React, { FC, useState } from 'react';
import { useMutation } from 'urql';

import {
  Card,
  CardCloseButton,
  CardOptions,
  ErrorMessage,
  Form,
  NumberFormatField,
  SubmitButton,
  useModalContext,
} from '@/components';
import { useTranslate } from '@/contexts';
import { LimitFragment } from '@/fragments/__generated__/LimitFragment';
import { limitFragment } from '@/fragments/Limits';
import { LimitTypeEnum } from '@/globalTypes';
import { useIsMounted } from '@/hooks';
import { Nullable } from '@/types';
import {
  ChangePlayerDepositLimitMutation,
  ChangePlayerDepositLimitMutationVariables,
} from './__generated__/ChangePlayerDepositLimitMutation';
import { PlayerDepositLimitsFormStaticQuery } from './__generated__/PlayerDepositLimitsFormStaticQuery';

const query = graphql`
  query PlayerDepositLimitsFormStaticQuery {
    sanityPlayerDepositLimitsForm {
      title {
        ...LocaleString
      }
      dailyLabel {
        ...LocaleString
      }
      weeklyLabel {
        ...LocaleString
      }
      monthlyLabel {
        ...LocaleString
      }
      submit {
        ...LocaleString
      }
    }
  }
`;

const changeDepositLimitMutation = gql`
  mutation ChangePlayerDepositLimitMutation(
    $playerId: ID!
    $amount: PositiveBigDecimal!
    $limitPeriodType: LimitTypeEnum!
  ) {
    setDepositLimit(
      amount: $amount
      playerId: $playerId
      limitPeriodType: $limitPeriodType
    ) {
      id
      depositLimit {
        dayLimit {
          ...LimitFragment
        }
        weekLimit {
          ...LimitFragment
        }
        monthLimit {
          ...LimitFragment
        }
      }
    }
  }
  ${limitFragment}
`;

const PlayerDepositLimitsForm: FC<{
  playerId: string;
  limitPeriodType: LimitTypeEnum;
  limit: Nullable<LimitFragment>;
  currency: Nullable<string>;
}> = ({ playerId, limit, limitPeriodType, currency }) => {
  const staticData = useStaticQuery<PlayerDepositLimitsFormStaticQuery>(query);

  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);
  const isMounted = useIsMounted();

  const form = staticData.sanityPlayerDepositLimitsForm;

  const [changePlayerDepositLimitState, changePlayerDepositLimit] = useMutation<
    ChangePlayerDepositLimitMutation,
    ChangePlayerDepositLimitMutationVariables
  >(changeDepositLimitMutation);

  const defaultValues = {
    amount: limit?.value,
  };

  const { close } = useModalContext();

  const { t } = useTranslate();

  const onSubmit = (values: typeof defaultValues) => {
    if (playerId) {
      changePlayerDepositLimit({
        playerId,
        amount: values.amount ?? 0,
        limitPeriodType,
      }).then((res) => {
        if (res.error?.message && isMounted) {
          setErrorMessage(res.error?.message);
        } else if (close) {
          close();
        }
      });
    }
  };

  if (!form || !defaultValues) {
    return null;
  }
  return (
    <Card
      size="lg"
      title={t(form.title)}
      options={
        <CardOptions>
          <CardCloseButton />
        </CardOptions>
      }
    >
      <div className="p-3">
        <Form
          defaultValues={defaultValues}
          onSubmit={onSubmit}
          className="grid grid-cols-2 sm:grid-cols-3 gap-6"
        >
          <NumberFormatField
            title={t(
              limitPeriodType === LimitTypeEnum.Day
                ? form.dailyLabel
                : limitPeriodType === LimitTypeEnum.Week
                ? form.weeklyLabel
                : form.monthlyLabel,
            )}
            suffix={` ${currency}`}
            name="amount"
            id="PlayerDepositLimitsForm__amount"
            className="col-span-full"
          />

          <ErrorMessage message={errorMessage} />
          <SubmitButton
            value={t(form.submit)}
            disabled={changePlayerDepositLimitState.fetching}
          />
        </Form>
      </div>
    </Card>
  );
};

export default PlayerDepositLimitsForm;
