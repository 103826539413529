import Tippy from '@tippyjs/react';
import React, { useCallback } from 'react';

import { LimitPeriodDetailList } from '@/bits/limit-period-detail-list/component';
import {
  ConditionalWrap,
  ControlledModal,
  InlineIconButton,
} from '@/components';
import { Bar } from '@/components/Bar';
import { CloseIcon, EditIcon, InformationIcon } from '@/components/icons';
import { TrashIcon } from '@/components/icons/TrashIcon';
import { useTranslate } from '@/contexts';
import { LocaleString } from '@/contexts/__generated__/LocaleString';
import PlayerCancelDepositLimitForm from '@/forms/player-cancel-deposit-limit-form/PlayerCancelDepositLimitForm';
import PlayerDepositLimitsForm from '@/forms/player-deposit-limits-form/PlayerDepositLimitsForm';
import PlayerRemoveDepositLimitForm from '@/forms/player-remove-deposit-limit-form/PlayerRemoveDepositLimitForm';
import { LimitType, LimitTypeEnum } from '@/globalTypes';
import { Nullable } from '@/types';
import formatDate from '@/utils/formatter/formatDate';
import formatMoney from '@/utils/formatter/formatMoney';
import {
  PlayerDepositLimitsBox_player_wallet_depositLimit_dayLimit,
  PlayerDepositLimitsBox_player_wallet_depositLimit_monthLimit,
  PlayerDepositLimitsBox_player_wallet_depositLimit_weekLimit,
} from './__generated__/PlayerDepositLimitsBox';

type LimitPeriod =
  | PlayerDepositLimitsBox_player_wallet_depositLimit_dayLimit
  | PlayerDepositLimitsBox_player_wallet_depositLimit_weekLimit
  | PlayerDepositLimitsBox_player_wallet_depositLimit_monthLimit;

const getLimitBarValue = (limit: Nullable<LimitPeriod>) => {
  const value = limit?.value ?? 0;
  const available = limit?.available ?? 0;
  return (value - available) / value;
};

const PendingPeriod = ({
  limit,
  currency,
  playerId,
  limitPeriodType,
}: {
  limit: Nullable<LimitPeriod>;
  currency: Nullable<string>;
  playerId: Nullable<string>;
  limitPeriodType: LimitTypeEnum;
}) => {
  if (!limit?.pendingPeriodLimit) {
    return null;
  }

  return (
    <div>
      Limit will be updated to{' '}
      {formatMoney(limit.pendingPeriodLimit.value, currency)} on{' '}
      {formatDate(limit.pendingPeriodLimit.validFrom)}
      <ControlledModal
        content={
          playerId ? (
            <PlayerCancelDepositLimitForm
              playerId={playerId}
              limitPeriodType={limitPeriodType}
            />
          ) : null
        }
      >
        <InlineIconButton>
          <CloseIcon />
        </InlineIconButton>
      </ControlledModal>
    </div>
  );
};

const PendingLimitRemovalMessage = ({
  limit,
}: {
  limit: Nullable<LimitPeriod>;
}) => {
  if (!limit?.pendingPeriodLimitRemoval) {
    return null;
  }

  return (
    <div>
      Limit will be removed on{' '}
      {formatDate(limit.pendingPeriodLimitRemoval.validFrom)}
    </div>
  );
};

type DepositLimitBarProps = {
  playerId?: string;
  fetching: boolean;
  limit: Nullable<LimitPeriod>;
  currency: Nullable<string>;
  label: Nullable<LocaleString>;
  limitPeriodType: LimitTypeEnum;
};

export const DepositLimitBar = ({
  fetching,
  limit,
  currency,
  label,
  playerId,
  limitPeriodType,
}: DepositLimitBarProps) => {
  const { t } = useTranslate();

  const currencyValueFormatter = useCallback(
    (value: Nullable<number>) => formatMoney(value, currency),
    [currency],
  );

  return (
    <div>
      <div className="flex">
        <ConditionalWrap
          condition={!!limit}
          wrap={(children) => (
            <Tippy
              content={`Period ending: ${formatDate(
                limit?.currentPeriodEndTime,
              )}`}
            >
              <div className="w-full">{children}</div>
            </Tippy>
          )}
        >
          <Bar loading={fetching} value={getLimitBarValue(limit)}>
            {t(label, {
              value: formatMoney((limit?.value ?? 0) - (limit?.available ?? 0)),
              of: formatMoney(limit?.value, currency),
            })}
          </Bar>
        </ConditionalWrap>
        <ControlledModal
          content={
            playerId && limit ? (
              <LimitPeriodDetailList
                limit={limit}
                limitTypeTitle={LimitType.Deposit}
                limitPeriodType={limitPeriodType}
                formatValue={currencyValueFormatter}
              />
            ) : null
          }
        >
          <InlineIconButton disabled={!limit}>
            <InformationIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerDepositLimitsForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
                currency={currency}
                limit={limit}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <EditIcon />
          </InlineIconButton>
        </ControlledModal>
        <ControlledModal
          content={
            playerId ? (
              <PlayerRemoveDepositLimitForm
                playerId={playerId}
                limitPeriodType={limitPeriodType}
              />
            ) : null
          }
        >
          <InlineIconButton>
            <TrashIcon />
          </InlineIconButton>
        </ControlledModal>
      </div>
      <PendingPeriod
        currency={currency}
        limit={limit}
        limitPeriodType={limitPeriodType}
        playerId={playerId}
      />
      <PendingLimitRemovalMessage limit={limit} />
    </div>
  );
};
