import { gql, useMutation } from 'urql';

import { limitBlockingFragment } from '@/fragments/Limits';
import {
  BlockDepositLimit,
  BlockDepositLimitVariables,
} from './__generated__/BlockDepositLimit';
import {
  UnblockDepositLimit,
  UnblockDepositLimitVariables,
} from './__generated__/UnblockDepositLimit';

const BLOCK_SESSION_LIMIT = gql`
  mutation BlockDepositLimit($playerId: ID!) {
    blockDepositLimit(playerId: $playerId) {
      id
      depositLimit {
        ...LimitBlockingFragment
      }
    }
  }
  ${limitBlockingFragment}
`;

const UNBLOCK_SESSION_LIMIT = gql`
  mutation UnblockDepositLimit($playerId: ID!) {
    # TODO: limitPeriodType should not be here
    unblockDepositLimit(playerId: $playerId) {
      id
      depositLimit {
        ...LimitBlockingFragment
      }
    }
  }
  ${limitBlockingFragment}
`;

export const useBlockDepositLimit = () => {
  return useMutation<BlockDepositLimit, BlockDepositLimitVariables>(
    BLOCK_SESSION_LIMIT,
  );
};

export const useUnblockDepositLimit = () => {
  return useMutation<UnblockDepositLimit, UnblockDepositLimitVariables>(
    UNBLOCK_SESSION_LIMIT,
  );
};
